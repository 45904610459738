import React from 'react';
import { connect } from 'react-redux';

import Layout from '../components/layout';
import SEO from '../components/seo';
import movement from './../helpers/movement';
import { updateCursor, updateWindow } from '../state/state-movement';
import anime from 'animejs'
import Img from "gatsby-image"
import {isMobile, isTablet} from 'react-device-detect';
import TransitionLink from 'gatsby-plugin-transition-link'
import Arrow from '../svg/left-arrow.svg'

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "favicon.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`


class IndexPage extends React.Component {

  render () {
    return (
      <>
        <SEO title="Under construction" bodyType="construction"/>
        <Layout 
          class={`construction`}
        >
          <Img 
            className="logo"
            fluid={this.props.data.logo.childImageSharp.fluid}
          />
          <h1>Telekinetik is currently repairing chairs...</h1>
        </Layout>
      </>
    )
  }
}

export default connect(
  state => ({ 
    window: state.movementState.windowDimensions,
    depth: state.movementState.depthLevels 
  }),
  dispatch => ({ 
    updateCursor: cursorObj => dispatch(updateCursor(cursorObj)),
    updateWindow: windowObj => dispatch(updateWindow(windowObj)),
  }),
)(IndexPage);